import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'

const ModalZone = styled.div`
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1000;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  display: ${({ opened }) => (opened ? 'flex' : 'none')};
  backdrop-filter: blur(0.8rem);
  overflow: auto;

  > div:first-child {
    transition: all 0.3s cubic-bezier(0.175, 0.885, 0.32, 1.275);
    opacity: ${({ opened }) => (opened ? 1 : 0)};
    transform: ${({ opened }) => (opened ? 'scale(1)' : 'scale(0.8)')};
    padding: 4rem;
    width: 60%;
    border-radius: 15px;
    background: #fff;
    z-index: 1002;
    overflow: auto;
    max-width: 600px;
  }

  > div.background {
    position: fixed;
    background: rgba(0, 0, 0, 0.6);
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    overflow: auto;
  }

  @media only screen and (max-width: 769px) {
    > div:first-child {
      padding: 20px;
      width: 90%;
    }
  }
`

const closeModal = fn => event => {
  event.stopPropagation()
  fn()
}

const Modal = ({ children, opened, close }) => (
  <ModalZone opened={opened}>
    <div>{children}</div>
    <div
      aria-label="Close Modal"
      className="background"
      onClick={closeModal(close)}
      onKeyPress={closeModal}
      role="button"
      tabIndex={0}
    />
  </ModalZone>
)

Modal.propTypes = {
  children: PropTypes.element.isRequired,
  opened: PropTypes.bool,
  close: PropTypes.func,
}

Modal.defaultProps = {
  opened: false,
  close: () => {},
}

export default Modal
