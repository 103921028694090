import React from 'react'
import styled from 'styled-components'
import { StaticQuery, graphql } from 'gatsby'
import Img from 'gatsby-image'
import friezeImg from '../images/frieze.svg'
import twitter from '../images/twitter.svg'
import linkedin from '../images/linkedin.svg'

const IntroDiv = styled.div`
  .presentation {
    margin: 0 auto;
    display: flex;
    align-items: center;
    justify-content: center;

    h1 {
      font-size: 28px;
      color: ${props => props.theme.mainBrandColor};
      margin: 0;
    }
  }

  .avatar {
    transform: translate(75px);
    z-index: 3;
  }

  .avatar__img {
    border-radius: 50%;
    width: 150px;
    height: 150px;
    padding: 0.5rem;
    border: solid 2px white;
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.1);
  }

  .presentation__content {
    background: white;
    border-radius: 10px;
    max-width: 700px;
    padding: 40px;
    min-height: 200px;
    box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.05);
    display: flex;
    justify-content: center;
    align-items: center;
    padding-left: 95px;
    position: relative;
  }

  .frieze {
    height: 111px;
    margin: 50px 0;
    background: url(${friezeImg});
    background-position: bottom center;
    background-repeat: repeat-x;
  }

  .share {
    display: flex;
    align-items: center;

    a {
      display: block;
      &:not(:last-child) {
        margin-right: 15px;
      }
    }
  }

  .contact {
    position: absolute;
    padding: 20px;
    bottom: 0;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    box-sizing: border-box;
    left: 0;
  }

  .tel {
    font-size: 20px;
    font-weight: bold;
    color: ${({ theme }) => theme.darkAccent};
    text-decoration: none;

    &:hover,
    &:focus {
      text-decoration: underline;
    }
  }

  @media only screen and (max-width: 769px) {
    .presentation {
      flex-direction: column;

      h1 {
        text-align: center;
      }
    }

    .avatar {
      transform: translate(0, 75px);
    }

    .presentation__content {
      padding: 80px 0;
    }
  }
`

const Intro = () => (
  <IntroDiv>
    <div className="presentation">
      <div className="avatar">
        <StaticQuery
          query={graphql`
            query {
              placeholderImage: file(relativePath: { eq: "avatar.jpg" }) {
                childImageSharp {
                  fluid(maxWidth: 150, maxHeight: 150) {
                    ...GatsbyImageSharpFluid
                  }
                }
              }
            }
          `}
          render={data => (
            <Img
              className="avatar__img"
              fluid={data.placeholderImage.childImageSharp.fluid}
            />
          )}
        />
      </div>
      <div className="presentation__content">
        <h1>Myriam Boulaire, Coordonnatrice SPS 1</h1>

        <div className="contact">
          <a className="tel" href="tel:+33689355117">
            06 89 35 51 17
          </a>
          <div className="share">
            <a href="https://twitter.com/myriamboulaire">
              <img src={twitter} alt="Compte twitter de Myriam Boulaire" />
            </a>

            <a href="https://fr.linkedin.com/in/myriam-boulaire-13813463">
              <img src={linkedin} alt="Compte twitter de Myriam Boulaire" />
            </a>
          </div>
        </div>
      </div>
    </div>

    <div className="frieze" />
  </IntroDiv>
)

export default Intro
