import React from 'react'

import styled from 'styled-components'

const DescriptionSection = styled.section`
  background: white;
  border-radius: 15px;
  box-shadow: 0 0 7px 0 rgba(0, 0, 0, 0.15);
  max-width: 950px;
  margin: 4rem auto;
  padding: 40px;
  color: ${({ theme }) => theme.mainBrandColor};

  h2 {
    font-size: 28px;
    color: ${({ theme }) => theme.mainBrandColor};
  }

  h3 {
    margin-bottom: 15px;
    margin-top: 20px;
    font-size: 23px;
  }

  p {
    text-align: justify;
  }

  b {
    font-weight: 600;
    background-image: linear-gradient(
      transparent 10px,
      ${({ theme }) => theme.darkShade} 10px
    );
  }

  ul {
    list-style: none;

    li:before {
      content: '✓';
      margin-right: 5px;
      color: #cbcbcb;
    }
  }

  @media only screen and (max-width: 769px) {
    padding: 20px;
    border-radius: 0;
  }
`

const Description = () => (
  <DescriptionSection>
    <h2>
      La mission du Coordonnateur Sécurité et Protection de la Santé (SPS)
    </h2>
    <h3>Démarrage de l’intervention / Phase de conception</h3>
    <p>
      Elle démarre dès la phase de conception. La première pièce du Registre
      Journal de Coordination est initié est la signature du contrat.
    </p>
    <p>
      En étant missionné dès <b>la phase APS </b>, nous constituons nos avis
      concernant la sécurité.
    </p>
    <p>
      Le Maître d'ouvrage peut ainsi consulter des entreprises qui vont inclure
      les éléments pour l’organisation du chantier et sa sécurité : clôtures de
      chantier, protocole de chargement et déchargement ...
    </p>
    <p>
      Le CSPS constitue le Plan Général de Coordination en matière de Sécurité
      et de Protection de la Santé (PGC SPS). Ce plan établit la coordination
      entre les entreprises en suivant les principes généraux de prévention.
    </p>
    <p>
      <b>Les principes généraux de prévention :</b>
    </p>
    <ul>
      <li>Lever les dangers</li>

      <li>
        Prioriser les actions de prévention après <b>évaluation des risques</b>.
        Mettre en oeuvre ses actions au plus tôt (phase de conception)
      </li>

      <li>
        <b>Adapter le travail</b> à l'Homme pour réduire les effets sur la
        santé.
      </li>

      <li>
        <b>Prendre en compte les évolutions</b> techniques et organisationnelles
      </li>
      <li>
        Choisir les procédés et les produits les moins dangereux pour un
        résultat attendu
      </li>

      <li>
        Technique, environnement, organisation et conditions de travail doivent
        faire parties de la prévention
      </li>

      <li>
        Prioriser la <b>protection collective</b> et en complément utiliser des
        équipements pour la protection individuel lorsque c'est nécessaire
      </li>

      <li>
        Assurer le partage de l’information, les travailleurs doivent connaître{' '}
        <b>les risques et les actions de prévention</b>
      </li>
    </ul>

    <p>
      Le coordonnateur constitue le DIUO : Dossier des Interventions Ultérieures
      sur l'Ouvrage (gardes-corps, accès en toiture...)
    </p>

    <h3>Phase de réalisation</h3>
    <p>
      Chaque entreprise fournit un PPSPS (Plan Particulier de Sécurité et de
      Protection de la Santé). Le coordonnateur les ajuste avec le PGC SPS.
      Ensuite le document est envoyé aux organismes de prévention (CARSAT,
      OPPBTP, DIRECCTE)
    </p>
    <p>
      Les moyens de prévention sont exposés aux entreprises lors de visites
      d'inspection.
    </p>
    <p>
      Le coordonnateur fait des visites de chantier et est présent aux réunions
      hebdomadaires.
    </p>
    <p>
      Pour les chantier de niveau 1, il organise la réunion trimestriel
      obligatoire à laquelle toutes les entreprises sont présentes.
    </p>
    <p>
      Il définit les conditions d'interventions pour la maintenance de l'ouvage
      (DIUO et DOE - Dossiers des Ouvrages Exécutés).
    </p>
  </DescriptionSection>
)

export default Description
