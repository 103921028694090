import React from 'react'
import styled from 'styled-components'

const StyledFooter = styled.footer`
  background: #121212;
  color: #bebebe;
  font-size: 12px;
  height: 40px;
  padding: 0 20px;
  box-sizing: border-box;
  width: 100%;
  color: ${({ theme }) => theme.lightShade};
  display: flex;
  align-items: center;
  flex-direction: row-reverse;
`

const Footer = () => (
  <StyledFooter>
    <div>Myriam Boulaire - Coordonnatrice SPS 1</div>
  </StyledFooter>
)

export default Footer
