import React from 'react'
import map from '../images/map.svg'
import styled from 'styled-components'
import Modal from './modal'
import axios from 'axios'

const InterventionSection = styled.section`
  background: ${({ theme }) => theme.lightAccent};
  padding: 60px 0;

  h2 {
    font-size: 23px;
    font-weight: 500;
    color: #121212;

    b {
      font-weight: 600;
      background-image: linear-gradient(
        transparent 10px,
        ${({ theme }) => theme.darkShade} 10px
      );
    }
  }

  .content {
    max-width: 950px;
    margin: 0 auto;
    background: white;
    display: flex;
    border-radius: 15px;
    box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.05);
  }

  .map {
    padding: 20px;
    border-right: 1px solid #ebebeb;
    display: flex;
    justify-content: center;
  }
  .map__figure {
    width: 150px;
    height: 150px;
  }

  .interventions {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    width: 100%;
    padding: 20px;
    text-align: center;
    box-sizing: border-box;
  }

  .actionButton {
    width: fit-content;
    padding: 15px 40px;
    font-size: 16px;
    color: white;
    margin-top: 40px;
    border: none;
    background: ${({ theme }) => theme.darkAccent};
    border-radius: 30px;
    cursor: pointer;
    transition: all 0.4s ease-in-out;

    &:hover,
    &:focus {
      background: #366390;
    }

    &.fade {
      background: #bebebe;
      margin-left: 20px;
    }
  }

  form {
    display: flex;
    flex-direction: column;

    input,
    textarea {
      color: rgb(90, 90, 90);
      font-size: 15px;
      width: 100%;
      box-sizing: border-box;
      padding: 1.2rem 4.2rem 1.2rem 2rem;
      border-radius: 15px;
      border: 1px solid #ebebeb;
      min-width: 150px;
      margin-bottom: 20px;
      resize: none;

      &:required {
        box-shadow: none;
      }

      &:focus {
        border-color: ${({ theme }) => theme.mainBrandColor};
      }
    }

    div {
      align-self: center;
      display: flex;
    }

    button.actionButton {
      margin: 0 auto;
    }
  }

  @media only screen and (max-width: 769px) {
    > div {
      flex-direction: column;
    }

    .map {
      border-bottom: 1px solid #ebebeb;
      border-right: none;
    }
  }
`

class Intervention extends React.Component {
  state = {
    modalOpened: false,
    email: '',
    name: '',
    message: '',
    sent: false,
  }

  change = event => {
    this.setState({ [event.target.name]: event.target.value })
  }

  toggle = () => {
    this.setState(prevState => ({
      modalOpened: !prevState.modalOpened,
    }))
  }

  submit = event => {
    event.preventDefault()

    const { name, email, message } = this.state

    axios
      .post('https://formcarry.com/s/dl2OXV6PUYl', { name, email, message })
      .then(result => {
        this.setState({ modalOpened: false, sent: true })
      })

    return false
  }

  render = () => (
    <InterventionSection>
      <div className="content">
        <div className="map">
          <img
            className="map__figure"
            src={map}
            alt="Coordonnatrice CSP1 sur l'Ile de France et Morbihan"
          />
        </div>
        <div className="interventions">
          {this.state.sent ? (
            <p>
              Votre message a bien été envoyé, je reviens vers vous au plus
              vite. Merci
            </p>
          ) : (
            <>
              <h2>
                J'interviens principalement sur l’ <b>Ile de France</b> et le{' '}
                <b>Morbihan</b>
              </h2>
              <button className="actionButton" onClick={this.toggle}>
                Me Contacter
              </button>
            </>
          )}
        </div>
      </div>

      <Modal opened={this.state.modalOpened} close={this.toggle}>
        <form method="POST" onSubmit={this.submit}>
          <input
            type="email"
            placeholder="Email"
            name="email"
            onChange={this.change}
            required
          />
          <input
            type="name"
            name="name"
            placeholder="Prénom Nom"
            onChange={this.change}
            required
          />
          <textarea
            name="message"
            placeholder="Message"
            row="4"
            onChange={this.change}
            required
          />
          <div>
            <button className="actionButton">Envoyer</button>
            <button
              type="button"
              onClick={this.toggle}
              className="actionButton fade"
            >
              Annuler
            </button>
          </div>
        </form>
      </Modal>
    </InterventionSection>
  )
}

export default Intervention
