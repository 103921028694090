import React from 'react'

import Layout from '../components/layout'
import Intro from '../components/intro'
import Description from '../components/description'
import Intervention from '../components/intervention'
import Actions from '../components/actions'
import Footer from '../components/footer'
import Customers from '../components/customers'

const IndexPage = () => (
  <Layout>
    <Intro />
    <div className="frieze" />
    <Actions />
    <Intervention />
    <Customers />
    <Description />
    <Footer />
  </Layout>
)

export default IndexPage
