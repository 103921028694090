import React from 'react'
import { StaticQuery, graphql } from 'gatsby'
import Img from 'gatsby-image'
import styled from 'styled-components'

export const logoImage = graphql`
  fragment logoImage on File {
    childImageSharp {
      fluid(maxHeight: 150) {
        ...GatsbyImageSharpFluid
      }
    }
  }
`

const query = graphql`
  query {
    cherpantier: file(relativePath: { eq: "cherpantier.png" }) {
      ...logoImage
    }
    robinAndCo: file(relativePath: { eq: "robin&co.png" }) {
      ...logoImage
    }
    snl: file(relativePath: { eq: "SNL.png" }) {
      ...logoImage
    }
    mutuelleFamiliale: file(relativePath: { eq: "mutuelleFamiliale.png" }) {
      ...logoImage
    }
    co2: file(relativePath: { eq: "co2.png" }) {
      ...logoImage
    }
    oph: file(relativePath: { eq: "oph.png" }) {
      ...logoImage
    }
  }
`

const CustomerZone = styled.div`
  background: white;
  padding: 5rem 0;

  h2 {
    text-align: center;
    margin-bottom: 30px;
    font-size: 28px;
    color: ${({ theme }) => theme.mainBrandColor};
  }

  > div {
    display: flex;
    margin: auto;
    max-width: 700px;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;

    .cust {
      width: 33.3%;
    }

    .customer__img {
      max-width: 115px;
      max-height: 115px;
      width: auto;
      height: auto;
      margin: 20px auto;
      object-fit: contain;
    }

    .cherpantier {
      max-width: 200px;
    }

    .mutuelle {
      max-width: 200px;
    }
  }

  .withoutLogos {
    padding: 0;
    margin-top: 30px;
    font-weight: bold;
    text-align: center;
  }
`
const Customers = () => (
  <CustomerZone>
    <h2>Ils me font confiance</h2>
    <div>
      <StaticQuery
        query={query}
        render={data => (
          <>
            <div className="cust">
              <Img
                className="customer__img cherpantier"
                fluid={data.cherpantier.childImageSharp.fluid}
                title="Cherpantier"
                alt="Cherpantier"
              />
            </div>
            <div className="cust">
              <Img
                className="customer__img"
                fluid={data.robinAndCo.childImageSharp.fluid}
                title="Robin & Co"
                alt="Robin & Co"
              />
            </div>
            <div className="cust">
              <Img
                className="customer__img"
                fluid={data.co2.childImageSharp.fluid}
                title="CO2 Architecture"
                alt="CO2 Architecture"
              />
            </div>
            <div className="cust">
              <Img
                className="customer__img"
                fluid={data.snl.childImageSharp.fluid}
                title="SNL"
                alt="SNL"
              />
            </div>
            <div className="cust">
              <Img
                className="customer__img mutuelle"
                fluid={data.mutuelleFamiliale.childImageSharp.fluid}
                title="La Mutuelle Familiale"
                alt="La Mutuelle Familiale"
              />
            </div>
            <div className="cust">
              <Img
                className="customer__img"
                fluid={data.oph.childImageSharp.fluid}
                title="OPH Chateaudun"
                alt="OPH Chateaudun"
              />
            </div>
          </>
        )}
      />

      <ul className="withoutLogos">
        <li>KLEMATIS - Paris</li>
        <li>SAS VIVARAIS / SKYLINES - Paris </li>
        <li>...</li>
      </ul>
    </div>
  </CustomerZone>
)

export default Customers
