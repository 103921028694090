import React from 'react'
import risk from '../images/risk.svg'
import accessibility from '../images/accessibility.svg'
import audit from '../images/audit.svg'
import styled from 'styled-components'

const ActionZone = styled.div`
  max-width: 900px;

  margin: 120px auto;
  display: flex;
  justify-content: space-around;

  figure {
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;

    div {
      width: 120px;
      height: 120px;
      border-radius: 50%;
      background: ${({ theme }) => theme.mainBrandColor};
      display: flex;
      justify-content: center;
      align-items: center;
      margin-bottom: 20px;
      box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.05);

      img {
        width: 80px;
        height: 80px;
      }
    }

    figcaption {
      font-weight: 600;
      font-size: 22px;
      max-width: 100px;
      max-width: 250px;
      color: #121212;
    }

    margin-bottom: 30px;

    &:first-child {
      div {
        background: ${({ theme }) => theme.darkAccent};
      }
    }
    &:last-child {
      div {
        background: ${({ theme }) => theme.lapiLazuli};
      }
    }
  }

  @media only screen and (max-width: 769px) {
    flex-direction: column;
  }
`

const Action = () => (
  <ActionZone>
    <figure>
      <div>
        <img src={risk} alt="Logo prévention des risques" />
      </div>
      <figcaption>Prévention des risques</figcaption>
    </figure>

    <figure>
      <div>
        <img src={audit} alt="Logo Rapports / Audits" />
      </div>
      <figcaption>Rapports / Audits</figcaption>
    </figure>

    <figure>
      <div>
        <img src={accessibility} alt="Logo accessibilité handicapé" />
      </div>
      <figcaption>Accessibilité Handicapé (Ad' Ap)</figcaption>
    </figure>
  </ActionZone>
)

export default Action
